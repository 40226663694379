<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <QuickTranslation
          translation-key="groupUnit.label"
          tag="h1"
        />
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :headers="headers"
      :items="allGroupUnits"
      :search="search"
      :no-results-text="$t('common.ui.not_search_found')"
      :no-data-text="$t('common.ui.not_data_found')"
      :loading-text="$t('common.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('common.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('common.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-row class="d-flex justify-space-between align-baseline">
            <v-col class="pl-0" lg="4" md="4" sm="6" xl="4" cols="6">
              <v-text-field
               onkeypress="return (event.charCode != 34)" dense
               v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
                filled
                rounded
                color="primary"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('common.titles.filter')"
                single-line
                hide-details
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col lg="4" md="4" sm="4" xl="4" cols="4" class="text-end">
              <v-dialog persistent v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    tile
                    :elevation="0"
                    color="gray"
                    style="border-radius: 1.5em;"
                    v-on="on"
                  >
                    <v-icon small color="primary">mdi-plus</v-icon>
                    {{ $t("common.buttons.new") }}
                  </v-btn>
                </template>
                <v-card :light="true" color="white">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              onkeypress="return (event.charCode != 34)" color="primary"
                              v-on:keyup.86="replaceMeasurementUnitGroupName" v-on:keyup.ctrl.86="replaceMeasurementUnitGroupName"
                              v-model="editedItem.measurementUnitGroupName"
                              :label="$t('groupUnit.fields.name')"
                              required
                              class="required"
                              :rules="requireAndMaxRules(max)"
                              :counter="max"
                              maxlength="max"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                             onkeypress="return (event.charCode != 34)" color="primary"
                              v-on:keyup.86="replaceCode" v-on:keyup.ctrl.86="replaceCode"
                              v-model="editedItem.code"
                              :label="$t('groupUnit.fields.code')"
                              required
                              class="required"
                              :rules="requireAndMaxRules(maxCode)"
                              :counter="maxCode"
                              maxlength="maxCode"
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small tile outlined color="gray" @click="close">
                      {{ $t("common.buttons.cancel") }}
                    </v-btn>
                    <v-btn
                      small
                      tile
                      outlined
                      color="primary"
                      :disabled="!valid"
                      @click="save"
                    >
                      {{ $t("common.buttons.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.isInteger="{ item }">
        <v-icon small color="primary" class="ml-4" v-if="item.isInteger">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon small color="red" class="ml-4" v-if="!item.isInteger"></v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              class="mr-2"
              small
              @click="editItem(item)"
            >
              mdi-square-edit-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.edit") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("equipment.label") }}
            </span>
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              color="neuter"
              small
              @click="deleteItemDialog(item)"
            >
              mdi-trash-can-outline
            </v-icon>
          </template>
          <span>
            {{ $t("common.buttons.delete") }}
            <span style="font-size: 12px; color: #AAAAAA">
              {{ $t("equipment.label") }}
            </span>
          </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("common.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="primary" @click="deleteItem">
              {{ $t("common.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import i18n from "@/plugins/i18n";
import { mapActions, mapGetters } from "vuex";
import N_GroupUnitsService from "../../../services/N_GroupUnitsService";
import LanguajeService from "../../../services/LanguajeService";

export default {
  name: "GroupUnits",
  data() {
    return {
      // dialog
      dialog: false,
      showDialog: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      valid: false,
      max: 40,
      maxCode: 10,
      editedIndex: -1,
      search: "",
      editedItem: {
        language_key: "",
        measurementUnitGroupName: "",
        code: "",
      },
      deletingItem: {},
    };
  },
  created() {
    this.loadDependencies();
  },
  computed: {
    ...mapGetters({
      allGroupUnits: "groupunits/allGroupUnits",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules",
    }),
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("common.titles.new")
          : i18n.t("common.titles.edit")) +
        " " +
        i18n.t("groupUnit.label")
      );
    },
    headers: () => [
      {
        text: i18n.t("groupUnit.fields.name"),
        align: "left",
        value: "measurementUnitGroupName",
      },
      { text: i18n.t("groupUnit.fields.code"), value: "code" },
      {
        text: i18n.t("common.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "center",
      },
    ],
  },

  methods: {
    ...mapActions({
      fetchAllGroupUnits: "groupunits/fetchAllGroupUnits",
      saveGroupUnit: "groupunits/saveGroupUnit",
      languajeKeys: "general/getLanguajeKeys",
    }),
    loadDependencies() {
      this.fetchAllGroupUnits(this.$toast);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.form.reset();
      }, 300);
    },
    save() {
      let item = Object.assign({}, this.editedItem);
      item.language_key = item.id
        ? item.language_key
        : item.measurementUnitGroupName.replace(/ /g, "_").toLowerCase();
      item.languageKey = {
        language_key: {
          id: item.id ? item.id : -1,
          language_key: item.language_key,
          language_label: item.measurementUnitGroupName,
          language: LanguajeService.getLenguajeName(),
          isProcessed: false,
        },
      };
      this.saveGroupUnit([item, this.editedIndex, this.$toast]).finally(() => {
        this.languajeKeys([this.$toast]).finally(() => {
          this.fetchAllGroupUnits([this.$toast]);
        });
        this.$toast.success(i18n.t("groupUnit.notification.save"), {
          icon: 'mdi-check-circle',
          queueable: true
        });
        this.close();
      });
    },
    editItem(item) {
      this.editedIndex = this.allGroupUnits.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      this.dialogClose();
      N_GroupUnitsService.delete(this.deletingItem.id)
        .then((resp) => {
          this.$toast.success(this.$t("groupUnit.notification.deleted"), {
            icon: 'mdi-check-circle',
            queueable: true
          });
        })
        .catch((err) => {
          if (err.message.includes("406")) {
            this.$toast.info(this.$t("groupUnit.notification.inUse"), {
              icon: 'mdi-information-slab-circle',
              queueable: true
            });
          } else {
            this.$toast.error(this.$t("groupUnit.notification.deletedError"), {
              queueable: true,
            });
          }
        })
        .finally(() => {
          this.fetchAllGroupUnits([this.$toast]);
        });
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("common.titles.alert"),
        dialogText: i18n.t("common.notification.deleteItems"),
      });
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceMeasurementUnitGroupName(event){
      this.editedItem.measurementUnitGroupName =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCode(event){
      this.editedItem.code =  event.currentTarget.value.toString().replaceAll('"','')
    },
  },
};
</script>

<style lang="scss" scoped></style>
